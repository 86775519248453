import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'addquestion',
    loadChildren: () => import('./addquestion/addquestion.module').then( m => m.AddquestionPageModule)
  },
  {
    path: 'questionspage',
    loadChildren: () => import('./questionspage/questionspage.module').then( m => m.QuestionspagePageModule)
  },
  {
    path: 'updatepage',
    loadChildren: () => import('./updatepage/updatepage.module').then( m => m.UpdatepagePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
